body{
    overflow: hidden;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-img {
    width: 180px;
    height: 100px;
    padding-right: 15px;
}

.header-title {
    overflow-wrap: anywhere;
}

.body-content {
    background-image: linear-gradient(75deg, #f2d5b8, #1b53ad85, #3b82ea);
    overflow: auto;
    height: 85vh;
    position: relative;

}

@media (max-width: 600px) {
    /* .header-content {
        display: unset;
    } */

    .header-img {
        float: right;
    }

    .header-title {
        overflow-wrap: anywhere;
    }

    .header-img {
        width: 120px;
        height: 60px;
        padding-right: 15px;
    }


    /* .slick-div{
        height: 180px;
        width: 180px;
        text-align: center;
        border: 2px solid #00387D;
        border-color: #00387D;
        border-radius: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 60px;

    } */

}

.content {
    border-radius: 15px;
    margin-bottom: 5px;
}

.card-transparent {
    background: transparent !important;
    border: none !important;
}


.slick-div{
    height: 250px;
    width: 250px;
    text-align: center;
    border: 2px solid #00387D;
    border-color: #00387D;
    border-radius: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 60px;

}
.slick-name{
    display: flex;
    justify-content: center;
    margin-right: 50px;
    font-size: 18px;
    font-weight: bold;
    color: #00387D;
    margin-bottom: 10px;

}
.slick-designation{
    display: flex;
    justify-content: center;
    margin-right: 50px;
    font-size: 18px;

    margin-bottom: 10px;
}