.collapsible {
    background-color: white;
    color: #0071ff;
    cursor: pointer;
    padding: 8px;
    margin-bottom: 15px;
    width: 100%;
    border: none;
    border-radius: 25px;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: 600;
    transition: background-color 0.2s ease;
    text-align: center;
}
.collapsible-div{
    width: 100%;
    margin-bottom: 10px;
}
.active,
.collapsible:hover {
    background-color: #d5d5d5;
}

.collapsible::after {
    content: '\002B';
    /* Unicode for "+" */
    color: rgb(0, 0, 0);
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.active::after {
    content: '\2212';
    /* Unicode for "-" */
}

.collapsible-content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    overflow-y: auto;
    transition: max-height 0.2s ease-out;
    background-color: transparent;
    text-align: justify;
}